/** @jsx jsx */
import HomePageLayout from '../../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import PartnerForm from '../../../components/Forms/PartnerForm';
import Link from '@fify.at/gatsby-theme-base/src/components/Link/Link';
import Text_calltoaction from '../../../components/raw_templates/Text_calltoaction';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';

export default function PartnerWerden({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Werden Sie jetzt unser Partner!"
      description="Für WirkaufendeinHaus.at steht die Zusammenarbeit mit Menschen im Vordergrund, daher sind wir laufend an motivierten Menschen mit einer Mission interessiert."
      image={data.hero.childImageSharp.fluid}
      keywords={['Partner werden']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Werden Sie jetzt Partner!"
        subtitle="Starten Sie Ihre Erfolgsmission."
        image={data.hero}
      />
      <Section container="small">
        <div sx={{ marginBottom: 7 }}>
          <Heading>
            Starten Sie jetzt Ihre Erfolgsmission als Partner von
            WirkaufendeinHaus.at
          </Heading>
        </div>
        <p>
          Für WirkaufendeinHaus.at steht die Zusammenarbeit mit Menschen im
          Vordergrund, daher sind wir laufend an motivierten Menschen mit einer
          Mission interessiert. Unser Ziel ist es, durch gezielte
          Digitalisierung mehr Raum und Zeit für die Bedürfnisse und Beratung
          unserer Kunden zu schaffen.
        </p>
        <p>
          Unsere Partner wie auch unsere Mitarbeiter zeichnen sich durch
          professionelles Auftreten, absolute Ehrlichkeit und Transparenz in
          allen Bereichen Ihres Handelns aus. Freundlichkeit, Pünktlichkeit und
          Handschlagqualität gelten als Basis-Voraussetzung für unser Team.
        </p>
        <h3>Ihr Herz schlägt für Immobilien?</h3>
        <p>
          Sie sind bereits ein erfolgreiches und führendes Immobilienunternehmen
          in Ihrer Region, dann werden Sie jetzt Partner! Wir bieten für
          bestehende Maklerunternehmen hohe Planungssicherheit und
          Erfolgsmöglichkeiten für die Zukunft.
        </p>
        <p>
          <strong>Gemeinsam zukunftssicher auf das nächste Level!</strong>
        </p>
      </Section>
      <Section
        sx={{
          maxWidth: [null, null, 500, 760, 1100],
          marginX: 'auto',
          marginBottom: 7,
          boxShadow: `0 0 30px rgba(0,0,0,0.1)`,
          backgroundColor: '#fff',
        }}
      >
        <Heading
          sx={{ color: 'primary', fontWeight: 'bold', textAlign: 'center' }}
        >
          Ihre Erfolgsmission als Partner von WirkaufendeinHaus.at beginnt hier!
        </Heading>
        <p>
          Füllen Sie den nachfolgenden Fragebogen aus und senden Sie diesen
          gemeinsam mit Ihren Unterlagen an uns. Nach Sichtung der eingehenden
          Informationen, nehmen wir umgehend Kontakt mit Ihnen auf und
          vereinbaren einen persönlichen Gesprächstermin.
        </p>
        <PartnerForm location={location} />
        <p sx={{ '&&&': { marginTop: 4 } }}>
          <small>
            * <Link to="/datenschutzerklaerung/">Datenschutz</Link> ist uns
            besonders wichtig! Seien Sie versichert, dass wir Ihre Daten stets
            diskret und sicher verarbeiten.
          </small>
        </p>
      </Section>
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-partner-werden" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
